@import "../../assets/colors.css";
.manageUserWindow {
  select,
  select > * {
    color: var(--navy);
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  overflow: hidden;
  overflow-x: scroll;
  padding-left: 8px;
  white-space: nowrap;
  width: 100%;
  background-color: var(--darkGrey);
  border-radius: 0.5rem 0.5rem 0 0;
  &::-webkit-scrollbar {
    display: none;
  }
  table {
    width: 100%;
  }
}
