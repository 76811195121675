.jumbotron{
    width: 100%;
    height: 80vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.jumbotron__overlay{
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to top, rgba(0,8,15,1), rgba(0,0,0,0));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.jumbotron__overlay *{
    margin: 1% 2%;
}
.jumbotron__title{
    font-size: 3.1rem;
}
.jumbotron__caption{
    font-size: calc(.9vw + .9vh + .45vmin);
    letter-spacing: unset;
    max-width: 80%;
    font-weight: 100;
}

@media only screen and (min-width: 768px){
    .jumbotron__caption{
        font-size: calc(.7vw + .7vh + .35vmin);
    }
}