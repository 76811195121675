.duplicateInfoPage{
}
.duplicateInfoPage__duplicateContent{
    margin-top: 4%;
    padding: 0 5% 5% 5%;
    display: flex;
    flex-direction: column;
}
.duplicateContent__description{
    width: 100%;
    margin-bottom: 2%;
    text-align: left;
    font-size: 1.7rem;
}
.duplicateContent__description:nth-child(2){
    margin-top: 5rem;
    text-align: center;
    align-self: center;
}
.duplicateContent__description:nth-child(3){
    font-style: italic;
    align-self: center;
    width: fit-content;
    font-size: 1rem;
}
.duplicateContent__button{
    align-self: center;

}