@import "../../assets/colors.css";
.calendarPage__calendar {
  padding: 5%;
  padding-bottom: 0;
  width: 100%;
}
.calendar__tile {
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 0 15%;
  grid-template-areas:
    "date add"
    "circle circle";
  grid-template-columns: 55% 30%;
  min-height: 3rem;
  height: 15vh;
  border: none;
  width: 100%;
}
.calendar__tile .calendar__circle {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  grid-area: circle;
}
.calendar__tile .calendar__circle p {
  display: none;
}
.circle__content {
  width: 100%;
}
.calendar__tile abbr {
  grid-area: date;
  justify-self: flex-end;
}
.circle__add {
  grid-area: add;
}
.calendar__tile ::after {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 10px;
}
.calendar__tile .mobileMark::after {
  background-color: red;
}
.calendar__tile .weekend {
  background-color: var(--gold);
}
.calendar__tile .notMonth > abbr {
  color: gray;
}
.calendar__tile .today > abbr {
  background-color: red;
  color: var(--white);
  border-radius: 20px;
  width: calc(1.3vh + 1.3vw + 0.65vmin);
  max-height: 2rem;
}
.react-calendar__month-view__weekdays *,
.calendar__tile,
.react-calendar__navigation * {
  background-color: var(--white);
  border: none;
  text-decoration: none;
}
.react-calendar__navigation__arrow {
  width: 5%;
}
* {
  color: var(--navy);
  font-family: FuturaBook;
  font-size: calc(0.85vh + 0.85vw + 0.425vmin);
}

@media only screen and (min-width: 992px) {
  .calendarPage__calendar {
  }
  .calendar__tile {
  }
  .calendar__tile .calendar__circle {
    width: 100%;
    min-height: 5vh;
    height: 80%;
    max-width: 95%;
    background-color: inherit;
    border-radius: 2rem;
    justify-self: center;
  }
  .calendar__tile .calendar__circle p {
    width: calc(90%);
    background-color: inherit;
    font-size: 1.7vh;
    display: inline-block;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .calendar__tile ::after {
    content: none;
  }
}
