.timePicker{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.timePicker__clock{
    display: flex;
}
.timePicker__clock>*{
    margin-right: .5rem;
    margin-top: .5rem;
}