@import "../../assets/colors.css";
.numberInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.numberInput * {
  margin: 0.5em 0;
}
.numberInput__label {
  margin-top: 0;
}
.numberInput__input {
  padding: 1em;
  margin: 0;
  border-radius: 10px;
  border: none;
  width: 4rem;
  height: 2rem;
  background-color: var(--white);
}
