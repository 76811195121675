@import "../../assets/colors.css";
.manageUserTable {
  background-color: var(--grey);
  width: 100%;
  border-radius: 0 0 0.5rem.5rem;
  padding: 8px;
  padding-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  scrollbar-width: thin;
}
.manageUserTable  table {
  width: 100%;

  border-spacing: 5px;
}
.manageUserTable th,
.manageUserTable td {
  text-align: left;
  margin-right: 5px;
}
