@import "../../assets/colors.css";

.sideNav {
  height: 100vh;
  min-height: 100%;
  text-align: right;
  background-color: var(--navy);
  position: fixed;
  width: 100vw;
  top: 0;
  right: 0;
  z-index: 3;
  transition: transform 0.5s, opacity 0.6s;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

direction: rtl;
overflow: auto;
}
.sideNav > * {
  width: 100%;
}

.sideNav__topBar {
  display: flex;
  justify-content: space-between;
  height: 4rem;
}
.sideNav__cross {
  margin-left: auto;
}
.sideNav__cross {
  width: fit-content;
  font-size: 30px;
  margin: 0px;
  color: white;
}
.sideNav__nav {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  margin: 2rem 0;
  justify-items: center;
}
.nav__button {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.nav__button > svg {
  fill: var(--white);
  transition: fill 0.3s;
}
.nav__button:hover,
.nav__button:active > svg {
    fill: var(--yellow);
}
.nav__icon {
  background-color: var(--purple);
  width: fit-content;
  display: flex;
  padding: 1.3rem;
  border-radius: 4rem;
  margin-bottom: 0.25rem;
}
.nav__icon > svg {
  height: 2.5rem;
  width: 2.5rem;
}
.nav__icon > svg > path {
  object-fit: contain;
}
.pageList__pageItem {
  direction: ltr;
  height: 6rem;
  font-size: 1.5rem;
  width: 100%;
  list-style-type: none;
}
.pageList__pageLink {
  display: flex;
  margin: 0;
  border-top: solid --white 1px;
  text-align: center;
  width: 100%;
  height: 100%;
  max-height: unset;
  align-items: center;
  justify-content: flex-start;
}
.sideNav.hiddenNav {
  transform: translate3d(100vw, 0, 0);
}

@media only screen and (min-width: 992px) {
  .sideNav {
    width: 600px;

  }
  .sideNav__nav {
    display: none;
  }
}
