@import "../../assets/colors.css";

.navigation {
  background-color: var(--purple);
  width: 100vw;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  padding: 0 7%;
}
.navigation__right {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  max-width: 700px;
}
.navigation__homeNav {
  display: none;
  flex-grow: 1;
}
.navigation__logo {
  max-height: 90%;
  border-radius: 15px;
}
.navigation ul * {
  transition: color 0.25s;
}

@media only screen and (min-width: 992px) {
  .navigation .navigation__right .navigation__homeNav {
        display: block;
      }
    }

