@import "../../assets/colors.css";
.manageTitheFile {
  border: --white 5px solid;
  width: 100%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  padding: 2%;
}
.manageTitheFile * {
  color: var(--white);
}
.manageTitheFile h1 {
  text-align: center;
}
.manageTitheFile__sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.manageTitheFile__sections > * {
  min-width: 300px;
  flex-grow: 1;
  max-width: 50%;
  margin: 1%;
}
