@import "../../assets/colors.css";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: FuturaBook;
  min-width: fit-content;
  max-width: inherit;
  width: fit-content;
  text-align: center;
  margin: 10px;
  height: fit-content;
  text-decoration: none;
  cursor: pointer;
}
.button:focus {
  outline: none;
}
.button_link {
  text-decoration: none;
}
.transparent {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
.small {
  font-size: 15px;
  border-radius: 10px;
  padding: 10px;
}
.medium {
  width: fit-content;
  min-width: 150px;
  font-size: 20px;
  border-radius: 12px;
  padding: 15px;
}
.large {
  padding: 20px;
  border-radius: 15px;
  font-size: 25px;
}
.purple {
  background-color: var(--purple);
  color: var(--white);
  border-color: var(--white);
}
.yellowBG {
  border: none;
  background: var(--yellowGradient);
}
.whiteFont {
  color: var(--white);
}
.navyFont {
  color: var(--navy);
}
.whiteBorder {
  border: white 2px solid;
  border-radius: 12px;
}

.navyBorder {
  border: --navy 2px solid;
  border-radius: 12px;
}
