@import "../../assets/colors.css";
.displayBox {
  height: 17rem;
  max-height: 90vmin;
  margin: 1rem;
  border-radius: 2rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.displayBox__bg {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--navyTransparent);
  width: inherit;
  height: inherit;
}
.displayBox__bg h2,
.displayBox__bg a {
  width: fit-content;
}
.displayBox__bg p {
  font-family: FuturaBook;
}
.displayBox__bg > * {
  margin: 0.5rem 0;
  text-align: left;
}
.displayBox__bg.center {
  align-items: center;
  justify-content: center;
}

.displayBox__bg.justifyCenter {
  justify-content: center;
}

.displayBox__bg .cover {
  width: 100vw;
  height: 100%;
  margin: 0;
  border-radius: 0;
}
