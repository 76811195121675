@import "../../assets/colors.css";
.textInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.textInput * {
  margin: 0.5em 0;
}
.textInput__input {
  text-align: left;
  color: var(--navy);
  padding: 1em;
  border-radius: 10px;
  border: none;
  width: 100%;
}
textarea {
  resize: none;
}
