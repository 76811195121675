.signInSignUp *{
    text-align: left;
}
.signInSignUp * button{
    margin-left: 0;
}
.signInSignUp{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
@media only screen and (min-width: 768px){
    .signInSignUp{
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
    .signInSignUp>*{
        margin: 5%;
    }
}