@import "../../assets/colors.css";
.fileList {
  padding: 2%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 40vh;
}
.fileList ::-webkit-scrollbar-track {
  background-color: var(--navy);
}

.fileList ::-webkit-scrollbar {
  width: 10px;
  background-color: var(--white);
}

.fileList ::-webkit-scrollbar-thumb {
  background-color: var(--white);
  border: 2px solid var(--white);
}

.fileList ul {
  width: 100%;
}
.fileList__item {
  list-style-type: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.fileList__item span {
  flex-shrink: 1;
}
.fileList__item span .button {
  max-width: fit-content;
  margin: 0;
}
.fileList__item span img {
  max-width: 200px;
}
.fileList__item .arrow {
  font-size: 30px;
}
