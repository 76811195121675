:root{
    --gold: #dcc060;
    --navy: #00080F;
    --navyTransparent: rgba(0, 8, 15, .80);
    --white: #ffffff;
    --yellow: #F9D143;
    --yellowGradient: linear-gradient(180deg, rgba(249,209,67,1) 0%, rgba(220,192,96,1) 100%);
    --grey: #979797;
    --darkGrey: #888888;
    --purple: #340076;
}