@import "../../assets/colors.css";
.logo {
  height: 100%;
  min-width: 200px;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--white);
}
.logo img {
  height: 90%;
  max-height: 60px;
  margin-right: 5px;
}
.logo div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.logo a {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}
