@import "../../assets/colors.css";
.highlight {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  overflow: hidden;
}
.highlight__photo {
  width: 100%;
  height: 90vh;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.highlight__background {
  border-bottom-color: var(--navy);
  width: 110%;
  border-bottom-width: 200px;
  border-bottom-style: solid;
  border-left: 900px solid transparent;
}
.highlight > :not(:first-child) {
  position: relative;
  transform: translateY(-40px);
}
.highlight .purple {
  background-color: var(--purple);
}
.highlight__background {
  border-bottom-color: --purple !important;
}

.highlight__background {
  border-bottom-color: var(--navy);
}
@media only screen and (min-width: 768px) {
  .highlight {
    flex-direction: row-reverse;
    align-items: center;
  }
  .highlight__photo {
    width: 90%;
    height: 90vh;
  }
  .highlight__background {
    width: 100%;
    border-bottom: none;
    border-left: 250px solid var(--navy);
    border-top: transparent solid 800px;
  }
  .highlight > :not(:first-child) {
    transform: none;
    position: unset;
    width: 40%;
    height: 60%;
    min-height: 50vh;
    transform: translateX(20px);
  }
  .purple .highlight__background {
      border-left-color: --purple !important;
    }
  
}
