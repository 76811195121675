@import "../../assets/colors.css";
.welcome {
  background-color: var(--white);
  height: 90vh;
  min-height: fit-content;
  padding: 10% 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.welcome__banner {
  grid-area: ban;
  align-self: flex-start;
}
.welcome__greeting,
.welcome__greeting > * {
  font-family: FuturaMedium;
  font-size: calc(1.1vw + 1.1vh + 0.55vmin);
}
.worshipImage {
  grid-area: img;
  height: 40%;
  justify-self: center;
  display: none;
}
.welcome__greeting {
  grid-area: greet;
  text-align: center;
  color: var(--navy);
}
.greeting__highlight {
  color: #ae9232;
}
.welcome__end {
  grid-area: button;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome__findOutMore {
  justify-self: center;
  font-family: CormorantGaramont;
  border-radius: 0;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.welcome__end .signature {
  margin-bottom: 0rem;
  width: 80%;
}

@media only screen and (min-width: 768px) {
  .welcome {
    margin: auto 0;
    padding: 2% 30px;
    display: grid;
    grid-template-rows: 20% 55% 25%;
    grid-template-columns: 7.5% 35% 50% 7.5%;
    grid-template-areas:
      "ban ban img img"
      ". greet img img"
      ". button img img";
    }
    .worshipImage {
      height: 100%;
      width: 100%;
      object-fit: contain;
      display: block;
    }

    .welcome__banner {
      width: 130%;
    }
    .signature {
      width: 100%;
    }
}
