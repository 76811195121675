@import "../../assets/colors.css";
.checkboxInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  * {
    margin: 0.5em 0;
  }
  .checkboxInput__input {
    appearance: none;
    padding: 1em;
    border-radius: 10px;
    border: none;
    width: 2rem;
    height: 2rem;
    background-color: var(--white);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:checked {
      background-color: var(--yellow);
    }
    &:checked:after {
      content: "\2713";
      font-size: 22px;
      position: relative;
      color: var(--purple);
    }
  }
}
