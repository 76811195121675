@import "../../assets/colors.css";
.calendarForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 5%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.calendarForm__cross {
  margin-left: 0;
  width: fit-content;
}
.calendarForm__recurringInfo {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.calendarForm__recurringInfo > * {
  margin-right: 10px;
  margin-top: 10px;
}
.calendarForm__submit {
  margin-left: 0;
}
