@import "../../assets/colors.css";
.slideshow {
  --animLength: 0.5s;
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.slide {
  min-width: 100%;
  height: 80vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform var(--animLength);
}
.slideshow__button {
  position: absolute;
  font-size: 50px;
  width: 60px;
  height: 70%;
  opacity: 0.5;
}
.slideshow__button_right {
  right: 0;
}
.slideshow__overlay {
  position: absolute;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 80%;
}
.slideshow__overlay  > * {
}
.slideshow__overlay .logoOverlay {
  width: 100%;
  height: 70vh;
}
.slideshow__overlay .logoOverlay img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.showOverlay {
  animation: show --animLength * 2;
}
.hideOverlay {
  display: none;
}
.greetingOverlay {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 80vh;
}
.overlayButtonSlide {
  align-self: center;
  border-radius: 0;
  font-family: Cormorant;
  height: 85%;
  min-width: 100%;
}
@keyframes show {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .slideshow__overlay .greetingOverlay {
      font-size: 2em;
    }
    .overlayButtonSlide {
      display: block;
    }
}
